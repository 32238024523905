.faq-holder{
    width: 60vw;
    margin-left: 25vw;
    margin-bottom: 150px;
}
.fullscreen-image{
    height: 100vh;
    width: 100vw;
    background-size: auto 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba( 0, 0, 0, 0.5);
    position: fixed;
    top: 0;
}
@media only screen and (max-width: 800px){
    .faq-holder{
        width: 80vw;
        margin-left: 10vw;
    }
}