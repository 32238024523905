body{
    padding: 40px 0 0 0;
}
.faq-section--label{
    font-size: 50px;
    margin:  50px 0 50px 12vw;
    padding-left: 5vw;
    width: 150px;
    color: #000000;
    font-weight: bold;
    border-bottom: 3px solid rgb(255, 106, 106);
    white-space: nowrap;
}
.faq-section--label span{
    font-size: 20px;
    color: #ffc6c6;
}
.faq-title--holder{
    display: grid;
    grid-template-columns: 100px 300px;
    grid-template-rows: 1fr;
    width: 400px;
    margin: 30px auto;
    padding-top: 20px;
}
.faq-title--logo{
    background-image: url('./assets/logo-no-bg.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;    
    margin-top: -45px;
}
.footer{
    display: grid;
    column-gap: 20px;
    row-gap: 3px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    border-top: solid 2px #ffc6c6;
    background-color: #FFE0E0;
    width: 100vw;
    box-sizing: border-box;
    text-align: center;
    padding: 15px 50px 15px 50px;
}
.footer > a {
    display: block;
    margin: 0 auto;
    width: 130px;
    text-decoration: none;
    color: #ff8080;
    transition: all 250ms;
}
.footer > a:visited {
    color: #ff8080;
}
.footer > a:hover {
    color: #151515;
}

@media only screen and (max-width: 800px){
    .faq-section--label{
        font-size: 25px;
        margin: 5vw 0 10vw 10vw;
        padding: 0;
    }
    .faq-section--label span{
        font-size: 12px;
        padding: 0;
    }
    .faq-title--holder{
        padding: 0;
        width: 300px;
        grid-template-columns: 1fr;
        grid-template-rows: 100px 50px;
        text-align: center;
        margin: 0 auto;
    }
    .faq-title--logo{  
        margin-top: 0;
    }
    .footer{
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: 1fr;
        padding: 10px 50px 10px 50px;
    }
}